.faq-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.faq-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 10px;
  text-align: left;
}

.faq-question {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: transparent; /* Remove o fundo */
  border: none; /* Remove a borda */
  border-radius: 0; /* Remove o arredondamento */
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #e0e0e0;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 10px; /* Remove o padding */
  border: none; /* Remove a borda */
  background-color: transparent; /* Remove o fundo */
}

.faq-answer.show {
  max-height: 150px; /* Ajuste conforme necessário */
  padding: 10px 0; /* Adiciona um padding superior e inferior para espaçamento */
}

.icon {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.icon.show {
  transform: rotate(180deg);
}
