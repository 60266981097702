.dr-calculo-container {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .dr-calculo-container h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .text-slider {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin-top: 50px;
    height: 270px;
  }
  
  .text-item {
    display: inline-block;
    width: 100%;
    white-space: normal;
    vertical-align: top;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .text-item.active {
    opacity: 1;
  }
  
  .indicators {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ddd;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }
  
  .indicator.active {
    background-color: #333;
  }