.container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .box {
    flex: 1;
    padding: 50px; /* Ajuste o preenchimento conforme necessário */
    color: white; /* Cor do texto para caixas pretas */
  }

  .boxHeader {
    flex: 1;
    padding: 50px; /* Ajuste o preenchimento conforme necessário */
    color: white; /* Cor do texto para caixas pretas */
  }

  .boxDarker {
    flex: 1;
    padding: 50px; /* Ajuste o preenchimento conforme necessário */
    color: white; /* Cor do texto para caixas pretas */
  }

  .boxLight {
    flex: 1;
    padding: 50px; /* Ajuste o preenchimento conforme necessário */
    color: white; /* Cor do texto para caixas pretas */
  }

  .boxSlider {
    flex: 1;
    padding: 50px; /* Ajuste o preenchimento conforme necessário */
    color: white; /* Cor do texto para caixas pretas */
  }
  
  .boxLight:nth-child(odd) {
    background-color: rgb(218, 218, 220);
    color: black;
  }

  .boxHeader:nth-child(odd) {
    background-color: rgb(218, 218, 220);
  }
  
  .boxDarker:nth-child(even) {
    background-color: rgb(76, 66, 74);
    color: rgb(255, 255, 255); /* Cor do texto para caixas brancas */
  }

  .boxSlider:nth-child(even) {
    background-color: rgb(76, 66, 74);
    color: black; /* Cor do texto para caixas brancas */
  }