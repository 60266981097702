.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: rgb(255, 255, 255);
    min-width: 650px;
  }
  
  .main-layout {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .menu-lateral {
    flex-shrink: 0;
    height: 100hv;
    overflow: hidden;
  }

  .menu-lateral.hidden {
    display: none;
  }
  
  .hamburger-btn {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1000;
    border-radius: 5px; /* Bordas arredondadas */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona elevação */
  }
  
  .hamburger-btn div {
    width: 25px;
    height: 3px;
    background-color: rgb(12, 44, 69);
    margin: 5px 0;
  }
  
  .content-area {
    flex: 1;
    overflow-y: auto;
  }

  .menu-toggle-btn {
    display: none;
  }
  
  /* Media queries para responsividade */
  @media (max-width: 900px) {
    .main-layout {
      flex-direction: column;
    }
   
    .content-area {
      height: auto;
    }
  
    .menu-lateral.hidden {
      display: none;
    }

    .menu-lateral {
        display: none;
      }
    
      .menu-lateral.show {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        z-index: 999;
      }
    
      .hamburger-btn {
        display: block;
      }
  }