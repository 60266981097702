.image-gallery {
    display: flex;
    background-color: #00123dee;
    flex-direction: column; /* Alinha as imagens verticalmente */
    justify-content: center;
    align-items: center;
  }
  
  .image-container {
    position: relative;
    background-color: #ffffff00; /* Cor de fundo comum */
    text-align: center;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }


.image-buttonNormal {
  position: absolute;
  height: 120px;
  width: 220px;
  left: 50%;
  transform: translateX(-50%);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.2s;
  -webkit-tap-highlight-color: transparent; /* Remove o efeito de destaque no toque em dispositivos móveis */
}
  
  .image-buttonNormal.top {
    top: 20%;
  }
  
  .image-buttonNormal.middle {
    top: 65%;
    transform: translate(-50%, -50%);
  }
  
  .image-buttonNormal.bottom {
    bottom: 5%;
  }


  @keyframes pulse {
    0%, 100% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(1.05);
    }
  }
  
  .image-buttonNormal {
    transition: box-shadow 0.2s ease, opacity 0.2s ease;
    transform-origin: center;
  }
  

  .image-buttonNormal:active {
    transform: translateX(-50%) scale(0.95); /* Reduz o tamanho do botão quando clicado */
    opacity: 0.9; /* Reduz um pouco a opacidade */
  }


  .image-buttonBig {
  position: absolute;
  height: 170px;
  width: 270px;
  left: 50%;
  transform: translateX(-50%);
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.2s;
  -webkit-tap-highlight-color: transparent; /* Remove o efeito de destaque no toque em dispositivos móveis */
}

.image-buttonBig.middle {
  top: 55%;
  transform: translate(-50%, -50%);
}
  
.image-buttonBig {
  transition: box-shadow 0.2s ease, opacity 0.2s ease;
  transform-origin: center;
}


.image-buttonBig:active {
  transform: translateX(-50%) scale(0.95); /* Reduz o tamanho do botão quando clicado */
  opacity: 0.9; /* Reduz um pouco a opacidade */
}