.header-container {
  background-color: #f8f9fa; /* Cor de fundo */
  padding: 50px 20px; /* Espaçamento interno */
  text-align: center; /* Centralizar texto */
  border-bottom: 2px solid #dee2e6; /* Linha de borda inferior */
}

.header-container h1 {
  font-size: 32px; /* Tamanho da fonte */
  color: #343a40; /* Cor do texto */
  margin-bottom: 20px; /* Espaço inferior */
}

.header-container p {
  font-size: 18px; /* Tamanho da fonte */
  color: #6c757d; /* Cor do texto */
  margin-bottom: 30px; /* Espaço inferior */
}

.cta-button {
  display: inline-block;
  padding: 15px 30px; /* Espaçamento interno */
  font-size: 18px; /* Tamanho da fonte */
  color: #fff; /* Cor do texto */
  background-color: #28a745; /* Cor do botão */
  border: none; /* Remover borda */
  border-radius: 5px; /* Bordas arredondadas */
  text-decoration: none; /* Remover sublinhado */
  transition: background-color 0.3s ease; /* Transição suave */
}

.cta-button:hover {
  background-color: #218838; /* Cor do botão ao passar o mouse */
}